import {
		Backdrop,
		Grow,
		Modal,
} from '@material-ui/core';
import $ from 'jquery';
import React, { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import Switch from "react-switch";
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import Security from '../../commons/security/Security.js';
import * as Utils from '../../commons/utils/Utils';

const RutasGridExport = (props) => {
	const [loading, setLoading] = useState(false)
	const [dataToExport, setDataToExport] = useState({
		nombre: true,
		base: true,
		activo: true
	})

	const handleDataExport = (nombre)=>{
		const miState = {...dataToExport};
		switch (nombre) {
			case "nombre":
				miState.nombre = !miState.nombre;
				break;
			case "base":
				miState.base = !miState.base;
				break;
			case "activo":
				miState.activo = !miState.activo;
				break;
		}
		setDataToExport(miState);
	}

	const handleExportExcel = (event) => {
		
		let queryFilters = props.queryFilters();
		event.preventDefault()
		setLoading(true)
		let auxDataToExport = JSON.stringify({
			colsAExportar: dataToExport,
		 
		})
		Utils.sendDataBlob(`/rutas/exportar-excel${queryFilters}`,'POST',auxDataToExport)
			.then(data => {
				let fileBlob = new File([data],'fleet-moviles.xlsx')
				let fileUrl = URL.createObjectURL(fileBlob);
				$("<a />", {
					href: fileUrl,
					download: "fleet-moviles.xlsx"
				}).appendTo("body").get(0).click();
			}).catch((err) => {
				console.log(err)
			}).finally(() => {
				props.handleClose();
				setLoading(false)
			})
	}

	return(
		<Modal open={props.open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 1000,
			}}
		>
			<Grow in={props.open}>
				<div className="" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
					<div className="modal-xl modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header bg-fleet">
								<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="movilesGrid.render.export_modal.header_datos_generales" defaultMessage=" Exportar Moviles"/></h4>
								<button type="button" onClick={()=>props.handleClose()} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<div className="modal-body modal-grey modal-mh ovf-x-hidden">
								<div className="row">
									<div className="col-md-12">
										<form className="form form-horizontal">
											<div className="form-body">
												<div className="card pull-up">
													<div className="card-content">
														<div className="card-body">
															<h4 className="form-section">
																<i className="la la-columns"></i><FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.header_columnas_a_exportar" defaultMessage=" Columnas a exportar"/>
															</h4>
															<div className="row m-1">
																<div className=" col-md-3">
																	<label className="col-md-6 label-control col-form-label" htmlFor="nombre">
																		<FormattedMessage id="ID_Ruta" defaultMessage="ID Ruta"/>:
																	</label>
																	<Switch
																		onChange = {(event) => handleDataExport("nombre")}
																		checked = {dataToExport.nombre ? true : false}
																		value={dataToExport.nombre}
																		id = "nombre"
																		name = "nombre"
																		offColor = "#FF4961"
																		onColor = "#28D094"
																		disabled = {false}
																		className =  "col-md-6 align-middle"
																	/>
																</div>
																<div className=" col-md-3">
																	<label className="col-md-6 label-control col-form-label" htmlFor="base">
																		<FormattedMessage id="Base" defaultMessage="Base"/>:
																	</label>
																	<Switch
																		onChange = {(event) => handleDataExport("base")}
																		checked = {dataToExport.base ? true : false}
																		value={dataToExport.base}
																		id = "base"
																		name = "base"
																		offColor = "#FF4961"
																		onColor = "#28D094"
																		disabled = {false}
																		className =  "col-md-6 align-middle"
																	/>
																</div>
																<div className=" col-md-3">
																	<label className="col-md-6 label-control col-form-label" htmlFor="activo">
																		<FormattedMessage id="Activo" defaultMessage="Activo"/>:
																	</label>
																	<Switch
																		onChange = {(event) => handleDataExport("activo")}
																		checked = {dataToExport.activo ? true : false}
																		value={dataToExport.activo}
																		id = "activo"
																		name = "activo"
																		offColor = "#FF4961"
																		onColor = "#28D094"
																		disabled = {false}
																		className =  "col-md-6 align-middle"
																	/>
																</div>
																					
																					
															</div>
																				
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-12 alert alert-info small" role="alert">
												<FormattedMessage id="movilesGrid.render.export_modal.esta_accion_puede_demorar_unos_minutos" defaultMessage="Esta acción puede demorar unos minutos."/>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="modal-footer modal-grey">
								<button type="button" className="btn btn-default btn-fleet" onClick={(e)=>handleExportExcel(e)} ><i className={loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="movilesGrid.render.export_modal.finish_button.exportar_a_excel" defaultMessage=" Exportar a Excel"/></button>
								<button type="button" className="btn btn-danger" onClick={()=>props.handleClose()} ><i className="fa fa-times-circle"></i><FormattedMessage id="movilesGrid.render.export_modal.finish_button.cancelar" defaultMessage=" Cancelar"/></button>
							</div>
						</div>
					</div>
				</div>
			</Grow>
		</Modal>
	)
}

export default RutasGridExport
