import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import swal from 'sweetalert'
import Config from '../../commons/config/Config.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import $ from 'jquery'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import moment from 'moment'
import 'moment/min/locales'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';

const stringOrNode = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.node,
]);

class RutasAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        nombre: null,
        activo: true,
        base: null,
        horario: null,
        tipoRuta: null
      },
      estados: [{ id: 1, nombre: 'Activo'},{ id: 2, nombre: 'Inactivo'}],
      errors: [],
      loading: false,
      basesLoading: false,
      bases: [],
      horarios: [
        {value: 'Matutina', label: this.props.intl.formatMessage({ id: 'Matutina', defaultMessage: 'Matutina' })},
        {value: 'vespertina', label: this.props.intl.formatMessage({ id: 'vespertina', defaultMessage: 'vespertina' })},
        {value: 'Nocturna', label: this.props.intl.formatMessage({ id: 'Nocturna', defaultMessage: 'Nocturna' })}
      ]
    };

    this.handleEstadoChange = this.handleEstadoChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleFormChangeSelectObject = this.handleFormChangeSelectObject.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);


    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.nombre': (value) => Validator.notEmpty(value),
			}
    });

  }

  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}

	componentWillMount() {
    if ((Security.hasPermission('RUTAS_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('RUTAS_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('RUTAS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
      this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }
  }

  initForm() {
		this.setState({ loading: true });
    let component = this;
		Promise.all([
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/rutas/' + this.state.props.match.params.id) : null,
      this.ajaxHandler.getJson('/bases/select'),
		]).then((data) => {
      let formData = data[0];
      if(formData){
        if(formData.base){
          formData.base.value = formData.base.id
          formData.base.label = formData.base.descripcion
        }
        if(formData.horario){
          let resultado = this.state.horarios.find(horario => horario.value === formData.horario);
          formData.horario = resultado ? resultado : {value: formData.horario, label: formData.horario}
        }
        component.setState({
          formData: formData
        });
      }
      component.setState({
        bases: data[1] ? data[1] : []
      });

    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			component.setState({ loading: false });
    });
	}

   handleEstadoChange(name, estado) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = estado;
		this.setState({
			formData: formDataCopy
    });
  }

	handleInputFormChange(event) {

		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

    if(value){
      const alphanumericRegex = /^[a-z0-9]+$/i;
      if(!alphanumericRegex.test(value))
        return;
    }
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this

      this.ajaxHandler.fetch('/rutas' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
        method: this.props.action === 'ADD' ? 'POST' : 'PUT',
        body: JSON.stringify({
          ...this.state.formData
        }),
      }).then(response => {
        if(response.status !== 400) {
          
          component.exit();
        } else {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        }
        window.scrollTo(0,0);
      }).catch((error) => {
        component.ajaxHandler.handleError(error);
      }).finally(() => {
        this.setState({ loading: false });
      });                
   
    event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/rutas'
		});
	}

  handleFormChangeSelectObject(name, object) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = object;
    this.setState({
      formData: formDataCopy
    });
  }

	render() {
    this.formValidation.validate();
    let formData = this.state.formData;
    let validationState = this.formValidation.state;
    let state = this.state;
		return (

			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{
                this.state.errors.map((e, i) => 
                  <li key={i}>
                    <FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/>
                  </li>)
              }
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i> <FormattedMessage id="Datos Generales" defaultMessage="Datos Generales"/>
                        <div
                          className="float-right"
                          style={{fontSize:'14px'}}>* <FormattedMessage id="campos_requeridos" defaultMessage="campos requeridos"/>
                        </div>
                      </h4>
                      <div className="row">
                        {/* ID Ruta */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                              <FormattedMessage id="ID_Ruta" defaultMessage="ID Ruta"/> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.nombre}</div>
                              ) : (
                              <div>
                                <input 
                                  type="text"
                                  className="form-control"
                                  id="nombre"
                                  name="nombre"
                                  placeholder={this.props.intl.formatMessage({ id: 'ID_Ruta', defaultMessage: 'ID Ruta' })}
                                  value={this.state.formData.nombre}
                                  onChange={this.handleInputFormChange}
                                />
                                <div 
                                  className="help-block text-danger field-message"
                                  hidden={validationState.formData.nombre.pristine || validationState.formData.nombre.valid}>
                                  {validationState.formData.nombre.message}
                                </div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* BASE */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="base">
                              <FormattedMessage id="movilesAbm.render.general_data.label_base" defaultMessage="Base"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.base && this.state.formData.base.descripcion ? this.state.formData.base.descripcion : ''}
                                </div>
                              ) : (
                                <div>
                                  <Select
                                    id="base"
                                    name="base"
                                    placeholder={
                                      !this.state.basesLoading ?
                                        this.props.intl.formatMessage({ id: 'Seleccione', defaultMessage: 'Seleccione' }) 
                                        : ''
                                    }
                                    options={this.state.bases}
                                    valueKey='value'
                                    labelKey='label'
                                    value={this.state.formData.base}
                                    onChange={(e) => this.handleFormChangeSelectObject("base", e)}
                                    isLoading={state.basesLoading}
                                    disabled={state.basesLoading}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Tipo de Ruta */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="tipoRuta">
                              <FormattedMessage id="Tipo_de_Ruta" defaultMessage="Tipo de Ruta"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.nombre}</div>
                              ) : (
                              <div>
                                <input 
                                  type="text"
                                  className="form-control"
                                  id="tipoRuta"
                                  name="tipoRuta"
                                  maxLength="20"
                                  placeholder={this.props.intl.formatMessage({ id: 'Tipo_de_Ruta', defaultMessage: 'Tipo de Ruta' })}
                                  value={formData.tipoRuta}
                                  onChange={this.handleInputFormChange}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Horario */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="horario">
                              <FormattedMessage id="Horario" defaultMessage="Horario"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.horario && this.state.formData.horario.label ? this.state.formData.horario.label : ''}
                                </div>
                              ) : (
                                <div>
                                  <Select
                                    id="base"
                                    name="base"
                                    placeholder={this.props.intl.formatMessage({ id: 'Seleccione', defaultMessage: 'Seleccione' })}
                                    options={this.state.horarios}
                                    valueKey='value'
                                    labelKey='label'
                                    value={this.state.formData.horario}
                                    onChange={(e) => this.handleFormChangeSelectObject("horario", e)}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* ESTADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage id="Activo" defaultMessage="Activo"/>:
                            </label>
                            <div className="col-md-8">
                              <Switch
                                onChange={(e) => this.handleEstadoChange('activo', e)}
                                checked={formData.activo}
                                id="activo"
                                name="activo"
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="serviciosAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i>{" "}
                            {this.props.action === 'VIEW' ? 
                              this.props.intl.formatMessage({ id: 'Volver', defaultMessage: 'Volver' }) : 
                              this.props.intl.formatMessage({ id: 'Cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>


        
      




				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(RutasAbm);
