import {
    Collapse,
    Input,
    TableCell,
    TableRow,
} from '@material-ui/core';
import 'moment/min/locales';
import Select from 'react-select';
import React, { useEffect, useState, useRef } from "react";
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import swal2 from 'sweetalert2';
import CalendarioImg from '../../assets/images/tareas/calendario.svg';
import PreventivoAmarillo from '../../assets/images/tareas/preventivo_amarillo.svg';
import PreventivoRojo from '../../assets/images/tareas/preventivo_rojo.svg';
import PreventivoVerde from '../../assets/images/tareas/preventivo_verde.svg';
import PreventivoTicketRojo from '../../assets/images/tareas/preventivostickets-06-rojo.svg';
import VencimientoAmarillo from '../../assets/images/tareas/vencimiento_amarillo.svg';
import VencimientoRojo from '../../assets/images/tareas/vencimiento_rojo.svg';
import VencimientoVerde from '../../assets/images/tareas/vencimiento_verde.svg';
import VencimientoTicketRojo from '../../assets/images/tareas/vencimientostickets-07-rojo.svg';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import Security from '../../commons/security/Security.js';
import Timezone from '../../commons/timezone/Timezone.js';
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles';
import * as Utils from '../../commons/utils/Utils';
import Loading from '../ui/Loading.js';
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js';
import MovilesDetail from "./MovilesDetail";
import { List, ListItem, ListItemText, Chip, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MovilesGridRow = (props) => {
  const movil = props.movil;
  const columnsEnabled = props.columnsEnabled;
  const permissions = props.permissions;
  const baseUrl = props.baseUrl;
  const intl = useIntl();
  const proxPreventivo = movil.proximasTareasPreventivo;
  const proxVencimiento = movil.proximasTareasVencimiento;
  const estados = props.estados;
  const maxDifferenceKM = ConfigBusiness.get('moviles.editaKm.habilitado') === 'true' ? ConfigBusiness.get('moviles.editaKm.maximo') : 0;
  const [inputKm, setInputKm] = useState('');
  const [controles, setControles] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null)
  const [open, setOpen] = useState(false);
  const [isHidden, setIsHidden] = useState("hidden-personal");
  const [estadosActivos, setEstadosActivos] = useState([]);
  const [estadosInactivos, setEstadosInactivos] = useState([]);
  const [estadosResaltados, setEstadosResaltados] = useState([]);
  const [ticketsGestoria, setTicketsGestoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [estadosPrioridad, setEstadosPrioridad] = useState(null);
  const [rutas, setRutas] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);
  const [selectedRutas, setSelectedRutas] = useState(props.movil.rutas ? props.movil.rutas : []);
  const [inputValue, setInputValue] = useState('');
  const [loadingInitRutas, setLoadingInitRutas] = useState(true);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(()=>{
    getControles(movil.id)
    getEstados()
    getTicketsGestoria(movil.id);
    init();
  },[]);

  useEffect(()=>{
    if(isDropdownOpen){
      Promise.all([
        Utils.getData(`/rutas/simple-search?total=10&label=`),
      ])
      .then((data) => {
        setRutas(data[0])
        setLoadingInitRutas(false)
      })
      .catch((err) => {
        setLoadingInitRutas(false)
      })
      dropdownRef.current.focus()
    }
  },[isDropdownOpen]);

  const getEstado = (estado) => {
    if (estadosActivos.includes(estado)) {
      return "status-green";
    } else if  (estadosResaltados.includes(estado)){
      return "status-orange";
    } else if  (estadosInactivos.includes(estado)){
      return "status-red";
    } else {
      return "status-grey";
    }
  };

  const init = () => {
    let estadosPrioridad = ConfigBusiness.get('moviles.estadosPrioridad');
    estadosPrioridad = estadosPrioridad ? estadosPrioridad.split(',') : null;
    setEstadosPrioridad(estadosPrioridad);
  }

  /**
   * Returns the color for the state badge based on the vehicle's state and priority settings.
   * 
   * @param {string} estado - The current state of the vehicle.
   * @returns {string} A hex color code representing the state color.
   */
  const getEstadoStyleColor = (estado) => {
    if(estadosPrioridad && estadosPrioridad.length >=0){
      for (let prioridad of estadosPrioridad) {
        if (prioridad === 'estadosActivos' && estadosActivos.includes(estado)) {
            return "#28d094";
        } else if (prioridad === 'estadosResaltados' && estadosResaltados.includes(estado)) {
            return "#f77a1c";
        } else if (prioridad === 'estadosInactivos' && estadosInactivos.includes(estado)) {
            return "#ff4961";
        }
      }
      return "#e3ebf3";
    }else{
      if (estadosActivos.includes(estado)) {
        return "#28d094";
      } else if  (estadosResaltados.includes(estado)){
        return "#f77a1c";
      } else if  (estadosInactivos.includes(estado)){
        return "#ff4961";
      } else {
        return "#e3ebf3";
      }
    }
  }

  const getEstadoClasificadorText = (estado) => {
    if(props.estadosClasificador){
      if(props.filtroEstadosClasificador.arrayDisponibles && props.filtroEstadosClasificador.arrayDisponibles.includes(estado)){
        return <span style={{color:"#28d094"}}>{intl.formatMessage({ id: 'Disponible', defaultMessage: 'Disponible' })}</span>;
      }else if(props.filtroEstadosClasificador.arrayNodisponibles && props.filtroEstadosClasificador.arrayNodisponibles.includes(estado)){
        return <span style={{color:"#ff4961"}}>{intl.formatMessage({ id: 'No_disponible', defaultMessage: 'No disponible' })}</span>;
      }
    }
    return "";
  }

  const getControles = (id) => {
    Utils.getData('/moviles/' + id + '/detail/controles/null/null')
    .then((data) => {
      if (data.combustibles === 2 ||
        data.documentacionChofer === 2 ||
        data.documentacionMovil === 2 ||
        data.dtc === "rojo" ||
        data.enTaller === 3 ||
        data.enTaller === 1 ||
        data.gps === "rojo" ||
        data.infracciones === 2)
      {
        setControles('danger');
      } else {
        setControles('success');
      }
    });
  };

  const getEstados = () => {
    Promise.all([
			ConfigBusiness.get('moviles.estadosActivos'),
			ConfigBusiness.get('moviles.estadosInactivos'),
			ConfigBusiness.get('moviles.estadosResaltados')
    ]).then((data) => {
      setEstadosActivos(data[0] ? data[0].split(',') : null)
      setEstadosInactivos(data[1] ? data[1].split(',') : null)
      setEstadosResaltados(data[2] ? data[2].split(',') : null)
    })
  };

  const getTicketsGestoria = (id) => {
    if(Security.hasPermission('TICKETS_MOVIL_LISTAR')) {
      let component = this;
      Utils.getData('/tickets/gestoriaAbiertos/movil/' + id)
      .then(data => {
        setTicketsGestoria(data);
      });
    }
  }

  const HandleUpdateKm = (e) => {
    const km = e.target.value;
    if (km) { updateKm(km); }
  };

  const handleOnEnter = (e, name, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      HandleUpdateKm(e);
    }
  };

  const toggleCollapse = () => {
    setOpen(!open);
    if (isHidden === "hidden-personal") {
      setIsHidden("block");
    } else {
      setIsHidden("hidden-personal");
    }
  };

  const changeEstado = (estado) => {
    let activo = estadosActivos.findIndex(e => e === estado) !== -1 ? true : false;
    if (!activo && ticketsGestoria.length && Security.hasPermission('TICKETS_CANCELAR_GESTORIA')) {
      swal2.fire({
        title: "Este vehículo tiene " + ticketsGestoria.length + " tickets de gestoría asociados. ¿Desea cancelarlos?",
        type: "warning",
        showCancelButton: true,
        width: 490,
        confirmButtonColor: "#FF394F",
        cancelButtonColor: "#bfbcbc",
        confirmButtonText: "Cancelar Tickets",
        cancelButtonText: "Continuar, sin cancelaciones",
      }).then((result) => {
        if (result.value && result.value===true) {
          setLoading(true);
          Utils.sendData('/tickets/cancelacionMasiva', 'POST', 
          {
            tickets: ticketsGestoria.map((e) => e.id),
            comentario: 'Inhabilito Móvil'
          })
          .then(() => {
            Utils.sendDataMultilanguage('/moviles/' + movil.id + '/estado', 'PUT', {estado: estado, activo: activo}, intl)
            .then(() => {
              movil.estado=estado;
              swal2.fire(
                intl.formatMessage({ id: 'El_cambio_fue_guardado_con_Exito!', defaultMessage: 'El cambio fue guardado con Éxito!' }),
                " ",
                'success'
              );
            }).finally(()=>{
              setLoading(false);
            })
          }).catch(()=>{
            setLoading(false);
          })
        }else if(result.dismiss && result.dismiss==="cancel"){
          setLoading(true);
          Utils.sendDataMultilanguage('/moviles/' + movil.id + '/estado', 'PUT', {estado: estado, activo: activo}, intl)
          .then(() => {
            movil.estado=estado;
            swal2.fire(
              intl.formatMessage({ id: 'El_cambio_fue_guardado_con_Exito!', defaultMessage: 'El cambio fue guardado con Éxito!' }),
              " ",
              'success'
            );
          }).finally(()=>{
            setLoading(false);
          })
        }
      });
    }else if(!activo && ticketsGestoria.length) {
      swal2.fire(
        intl.formatMessage({
          id: 'Este_vehiculo_tiene_n_tickets_de_gestoria_asociados_cancele_los_tickets.',
          defaultMessage: 'Este vehículo tiene {cantidadTicketsGestoria} tickets de gestoría asociados, cancele los tickets.',
        },{cantidadTicketsGestoria: ticketsGestoria.length}),
        " ",
        "warning",
      )
    }else{
      swal2.fire({
        title: intl.formatMessage({ id: 'Cambiar_estado', defaultMessage: 'Cambiar estado' }),
        text: intl.formatMessage({
          id: 'Esta_seguro_que_desea_cambiar_el_estado_del_vehiculo',
          defaultMessage: '¿Está seguro que desea cambiar el estado del vehículo {dominio}?',
        },{dominio: movil.dominio}),
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#0ABFBC",
        cancelButtonColor: "#FF3145",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.value && result.value===true) {
          setLoading(true);
          Utils.sendDataMultilanguage('/moviles/' + movil.id + '/estado', 'PUT', {estado: estado, activo: activo}, intl)
          .then(() => {
            movil.estado=estado;
            swal2.fire(
              intl.formatMessage({ id: 'El_cambio_fue_guardado_con_Exito!', defaultMessage: 'El cambio fue guardado con Éxito!' }),
              " ",
              'success'
            );
          }).finally(()=>{
            setLoading(false);
          })
        }
      });
    }
  }

  const updateKm = (km) => {
    const service = '/moviles/'+movil.id+'/km';
    const data = {'km' : km};
    const title = "Diferencia de "+ UNIDAD_MEDIDOR[movil.unidadMedidorId]['unidades']+
    " mayor a " + maxDifferenceKM +" "+ UNIDAD_MEDIDOR[movil.unidadMedidorId]['unidades']+
    " Desea forzar este cambio?";

    if(Math.abs(km - movil.km_actual) > maxDifferenceKM) {
      swal({
        title: title,
        text: "",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          confirm: {
            text: 'Aceptar',
            value: true,
            visible: true,
            className: "btn btn-success",
            closeModal: false
          },
          cancel: {
            text: 'Cancelar',
            value: null,
            visible: true,
            className: "btn btn-danger",
            closeModal: false,
          }
        }
      }).then(isConfirm => {
        if (isConfirm) {
          Utils.sendData(service, 'PUT', data).then(() => {
            movil.km_actual = km;
            setInputKm('');
            swal('Se actualizó con Éxito!', " ", "success");
          });
        } else {
          swal('Actualización Cancelada!', "", "error");
          setInputKm('');
        }
      });
    } else {
      Utils.sendData(service, 'PUT', data).then(() => {
        movil.km_actual = km;
        setInputKm('');
        swal('Se actualizó con Éxito!', " ", "success");
      });
    }
  }

  const updateRutas = () => {
    if (Security.hasPermission('MOVILES_MODIFICAR_RUTA_GRILLA')) {
      swal2.fire({
        title: intl.formatMessage({ id: 'Actualizar_rutas', defaultMessage: 'Actualizar rutas' }),
        text: intl.formatMessage({
          id: 'Esta_seguro_que_quiere_actualizar_las_rutas',
          defaultMessage: '¿Está seguro que quiere actualizar las rutas?',
        }),
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#0ABFBC",
        cancelButtonColor: "#FF3145",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.value && result.value===true) {
          setLoading(true);
          Utils.sendDataMultilanguage('/moviles/actualizar/rutas', 'PUT', {id: movil.id, rutas: selectedRutas}, intl)
          .then((res) => {
            movil.rutas = selectedRutas;
            setInputValue('');
            setIsDropdownOpen(false);
            swal2.fire(
              intl.formatMessage({ id: 'El_cambio_fue_guardado_con_Exito!', defaultMessage: 'El cambio fue guardado con Éxito!' }),
              " ",
              'success'
            );
          }).finally(()=>{
            setLoading(false);
          })
        }
      });
    }
  }

  const handleBlur = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.relatedTarget) &&
        selectRef.current && !selectRef.current.contains(e.relatedTarget)) {
      setIsDropdownOpen(false);
      setSelectedRutas(movil.rutas)
      setInputValue('');
    }
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    setInputValue(value);
    Promise.all([
			Utils.getData(`/rutas/simple-search?total=10&label=${value}`),
		])
		.then((data) => {
      setRutas(data[0])
		})
  };

  const handleSelectOption = (option) => {
    if (!selectedRutas.find(e => e.id === option.id)) {
      setSelectedRutas([...selectedRutas, option]);
      setInputValue('');
      Promise.all([
        Utils.getData(`/rutas/simple-search?total=10&label=`),
      ])
      .then((data) => {
        setRutas(data[0])
      })
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setSelectedRutas((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const cancelarRutas = () => {
    setSelectedRutas(props.movil.rutas);
    setInputValue('');
    setIsDropdownOpen(false);
  }

  const rutaLabel = (ruta) => {
    let label = "";
    if(ruta.base){
      label += ruta.base.descripcion ? ruta.base.descripcion.substring(0, 4) : "";
    }
    label += ruta.nombre ? ruta.nombre : '';
    label += ruta.tipoRuta ? ruta.tipoRuta : '';
    return label;
  }

  const labelColumn = () => {
    let label = "";
    for (let i = 0; i < movil.rutas.length; i++) {
      label += rutaLabel(movil.rutas[i]);
      if(i < movil.rutas.length -1){
        label += ", "
      }
    }
    return label;
  }

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}
      <TableRow>
          <StyledTableCell show={props.generacionMasiva} align="left" style={{maxWidth: '30px'}}>
            <input type="checkbox" checked={props.selectedMoviles.includes(movil)} onChange={props.handleChecked(movil)} style={{ maxWidth: "22px" }} className="customCheckbox" id={movil.id}/>
          </StyledTableCell>
          <StyledTableCell align="left" className="all avatar-plus-detail">
            <div className="d-flex">
              <div onClick={()=>toggleCollapse()}>
                {open
                  ? ( <i className="fa fa-angle-double-down moviles-show-detail fa-2x align-self-center"></i>)
                  : ( <i className="fa fa-angle-double-right moviles-show-detail fa-2x align-self-center"></i>)
                }

              </div>
              <Logo
              codigo={movil.modelo_eloquent.marca_eloquent.codigo}
              nombre={movil.modelo_eloquent.marca_eloquent.nombre}
              marca={movil.modelo_eloquent.marca}
              />
            </div>
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.disponibilidadEnabled} align="left">
            {getEstadoClasificadorText(movil.estado)}
          </StyledTableCell>
          <StyledTableCell title={movil.estado} placement="right">
            <div className="d-flex align-items-center"
              style={{ 
                backgroundColor: getEstadoStyleColor(movil.estado),
                padding: "4px",
                borderRadius: "7px",
                color: "white",
                fontSize: "9px",
                backgroundSize: "auto",
                width: "fit-content",
                border: "1px solid"
              }}
            >
            <div>{movil.estado}</div>
                {Security.hasPermission('MOVILES_MODIFICAR_ESTADO_GRILLA') ||
                Security.hasPermission('MOVILES_MODIFICAR_ESTADO_GRILLA_ACTIVOS') ||
                Security.hasPermission('MOVILES_MODIFICAR_ESTADO_GRILLA_INACTIVOS') ?
                <div>
                  <i
                    id="dLabel"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="fa fa-chevron-down cursor-pointer"
                    style={{ marginRight: "5px" , marginLeft:"5px"}}
                  ></i>
                  <div className="dropdown-menu" aria-labelledby="dLabel">
                    {/* <p className="dropdown-item">Action</p> */}
                    {estados
                        ? estados.map((estado, i) => {
                            // Lógica para verificar si el estado actual puede ser modificado según los permisos
                            const puedeModificar = Security.hasPermission('MOVILES_MODIFICAR_ESTADO_GRILLA') ||
                                (Security.hasPermission('MOVILES_MODIFICAR_ESTADO_GRILLA_ACTIVOS') && estadosActivos.includes(estado.value)) ||
                                (Security.hasPermission('MOVILES_MODIFICAR_ESTADO_GRILLA_INACTIVOS') && estadosInactivos.includes(estado.value));

                            // Estilos para los estados deshabilitados
                            const estiloEstado = puedeModificar
                                ? {
                                    cursor: "pointer",
                                    margin: "0",
                                    padding: "5px 5px",
                                    borderLeft: "solid " + getEstadoStyleColor(estado.value)
                                }
                                : {
                                    cursor: "not-allowed",
                                    margin: "0",
                                    padding: "5px 5px",
                                    borderLeft: "solid grey", // Color gris para los estados no modificables
                                    color: "grey",            // Texto en gris para los estados deshabilitados
                                    opacity: 0.5              // Reducir opacidad para los deshabilitados
                                };

                            return estado.value !== movil.estado ? (
                                <p
                                    key={i}
                                    className="dropdown-item"
                                    style={estiloEstado}
                                    onClick={() => {
                                        if (puedeModificar) {
                                            changeEstado(estado.value);
                                        }
                                    }}
                                >
                                    {estado.label}
                                </p>
                            ) : null;
                        })
                        : ""}
                  </div>
                </div>
                : ""
              }
            </div>
            {/* <td
              align="left"
              className={getEstado(movil.estado) + " p-0 all custom-lineHeight br"}
              style={{ maxWidth: "3px"}}
            /> */}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.unidadEnabled} align="left">
            {movil.unidad}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.chasisEnabled} align="left">
            {movil.chasis_nro}
          </StyledTableCell>
          <StyledTableCell align="left">
            <div className="d-flex">
            <div>
              {movil.dominio}
            </div>
            <i id="dominio" className={"fas fa-circle datatables-status-icon-fa-fas-circle "+controles}></i>
            </div>
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.rutaEnabled} align="left">
          <div className="d-flex align-items-center">
              <div>
                {
                  labelColumn()
                }
              </div>
              {Security.hasPermission('MOVILES_MODIFICAR_RUTA_GRILLA') ?
                <div>
                  <i
                    className="fa fa-chevron-down fa-2xs cursor-pointer"
                    style={{ marginRight: "5px" , marginLeft:"5px", fontSize: "11px", marginBlock:"6px"}}
                    onClick={toggleDropdown}
                  ></i>
                  {isDropdownOpen && (
                    <div
                      style={{
                        position: 'relative',
                      }}
                      ref={dropdownRef}
                      tabIndex={1}
                    >
                      <div
                        style={{
                          position: 'static',
                        }}
                      >
                        <div
                          style={{
                            border: '1px solid #ccc',
                            padding: '10px',
                            backgroundColor: '#fff',
                            position: 'absolute',
                            zIndex: 10
                          }}
                        >
                          {!loadingInitRutas ?
                            <>
                              <div style={{ marginBottom: '10px' }}>
                                {intl.formatMessage({ id: 'Rutas_del_movil', defaultMessage: 'Rutas del móvil' })}:
                                {selectedRutas.length > 0 ?
                                  selectedRutas.map((option, index) => (
                                    <div className="" style={{paddingTop: "2px"}} key={index}>
                                      <Chip
                                        label={rutaLabel(option)}
                                        onDelete={() => handleDeleteChip(option)}
                                        style={{ marginRight: '5px' }}
                                      />
                                    </div>
                                  )) : intl.formatMessage({ id: 'No_tiene_rutas', defaultMessage: 'No tiene rutas' })}
                              </div>
                              
                              <div className="ruta-grilla-movil">
                                <i className="fa fa-search fa-xs"></i>
                                <input
                                  type="text"
                                  placeholder={intl.formatMessage({ id: 'Buscar_Ruta', defaultMessage: 'Buscar Ruta' })}
                                  onChange={(e) => handleInputChange(e)}
                                  value={inputValue}
                                  />
                              </div>
                              <List ref={selectRef}>
                                {rutas.map((option, index) => (
                                  <ListItem key={index} onClick={() => handleSelectOption(option)} style={{cursor: "pointer"}}>
                                    <ListItemText primary={rutaLabel(option)} />
                                  </ListItem>
                                ))}
                              </List>
                              <div className="d-flex justify-content-center">
                                <button className="btn btn-primary mr-1" onClick={() => updateRutas()}>
                                  {intl.formatMessage({ id: 'Actualizar', defaultMessage: 'Actualizar' })}
                                </button>
                                <button type="button" className="btn btn-danger" onClick={() => cancelarRutas()}>
                                  {intl.formatMessage({ id: 'Cancelar', defaultMessage: 'Cancelar' })}
                                </button>
                              </div>
                            </>
                          : <CircularProgress />}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                : ""
              }
            </div>
          </StyledTableCell>
          <StyledTableCell align="left">
            {movil.modelo_eloquent && movil.modelo_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell align="left">
            {proxPreventivo ? <ProximosPreventivos data={proxPreventivo} icono={UNIDAD_MEDIDOR[movil.unidadMedidorId]['icono_preventivos']} baseUrl={baseUrl}/> : ''}
            {proxVencimiento ? <ProximosVencimientos data={proxVencimiento}  baseUrl={baseUrl}/> : ''}
          </StyledTableCell>
          <StyledTableCell align="left">
            {movil.modelo_eloquent.modelo_tipo.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.paisEnabled} align="left">
            {movil.base_eloquent.pais && movil.base_eloquent.pais.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.regionEnabled} align="left">
            {movil.base_eloquent.region_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.subRegionEnabled} align="left">
            {movil.base_eloquent.sub_region_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.baseEnabled} align="left">
            {movil.base_eloquent.descripcion}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.titularEnabled} align="left">
            {movil.titular_eloquent && movil.titular_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.companiaOrigenEnabled} align="left">
            {movil.compania_origen}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.centroCostosEnabled} align="left">
            {movil.cecos && movil.cecos.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.cecosDireccionEnabled} align="left">
            {(movil.cecos && movil.cecos.direccion_eloquent) && movil.cecos.direccion_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.cecosMercadoEnabled} align="left">
          {(movil.cecos && movil.cecos.mercado_eloquent) && movil.cecos.mercado_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.cecosGerenciaEnabled} align="left">
          {(movil.cecos && movil.cecos.gerencia_eloquent) && movil.cecos.gerencia_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.cecosAreaEnabled} align="left">
          {(movil.cecos && movil.cecos.area_eloquent) && movil.cecos.area_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.responsable1Enabled} align="left">
            {movil.responsable1NombreApellido}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.supervisorEnabled} align="left">
            {movil.supervisorNombreApellido}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.temporalEnabled} align="left">
            {movil.temporalNombreApellido}
          </StyledTableCell>
          <StyledTableCell align="left">
            {
              movil.usuario_km_modificacion ? (
                <div className="text-center success dt-info-icon">
                  <StyledTooltip
                    title={ <div> Ult. actualización Medidor: <br/>
                      {Timezone.getDateForClient(movil.fecha_hora_km_modificacion, null, 'DD/MM/YYYY HH:mm')} <br/>
                      Usuario:
                      {(movil.usuario_km_modificacion.label ? movil.usuario_km_modificacion.label  : "Sistema")}
                    </div>}
                    arrow
                    placement="top"
                  >
                    <i className="la la-clock-o"></i>
                  </StyledTooltip>
                </div>
              ) : ''
            }
          </StyledTableCell>
          <StyledTableCell align="left">
            {Math.floor(movil.km_actual).toLocaleString('de-DE') + ' ' + UNIDAD_MEDIDOR[movil.unidad_medidor_id]['unidades']}
          </StyledTableCell>
          <StyledTableCell show={(columnsEnabled.editKmEnabled && permissions.canModifyKM) ? true : false}>
            <Input
              align="left"
              className="dt-input-grid input-km dt-input-holder input-no-arrows"
              disableUnderline={true}
              type="number"
              style={{border:"none"}}
              id={"dt_input_"+movil.id}
              data-id={movil.id}
              name={"dt_input_"+movil.id}
              size="10"
              value={inputKm}
              onChange={e => setInputKm(e.target.value)}
              onBlur={HandleUpdateKm}
              onKeyDown={(e) => handleOnEnter(e)}
            />
            </StyledTableCell>
          <StyledTableCell>
            <div className="d-inline-flex">
              {permissions.movilesVisualizar &&
                <button
                  className="action view btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Ver"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={() => setRedirectTo(baseUrl + '/' + movil.id)}
                >
                  <i className="fa fa-search fa-xs"/>
                </button>
              }
              {permissions.movilesModificar &&
                <button
                  className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Trabajar con el móvil"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={()=> setRedirectTo(baseUrl +'/'+ movil.id +'/edit')}
                  >
                    <i className="fa fa-pencil fa-xs"/>
                </button>
              }
              {(permissions.ticketsListar || permissions.vencimientosListar || permissions.preventivosListar) &&
                <button
                  className="action mantYVenc btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Ver Mantenimientos, Vencimientos y Tickets"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={()=> setRedirectTo(baseUrl +'/'+ movil.id +'/tickets')}
                  >
                  <i className="ft ft-clipboard"/>
                </button>
              }
              {permissions.correctivosCrear &&
                <button
                  className="action correctivo btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Crear Nuevo Mantenimiento Correctivo"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={()=> setRedirectTo('/correctivos/add/movil/'+ movil.id +'/'+ movil.dominio)}
                  >
                    <i className="fa fa-wrench fa-xs"/>
                </button>
              }
              {permissions.vencimientosCrear &&
                <button
                  className="action vencimiento btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Crear Nuevo Vencimiento"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={()=> setRedirectTo('/vencimientos/add')}
                  >
                    <i className="fa fa-calendar-plus-o fa-xs"/>
                </button>
              }
              </div>
            </StyledTableCell>
        </TableRow>
        <TableRow className={isHidden}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: "rgb(244, 245, 250)"}} colSpan={19}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <MovilesDetail
                intl={intl}
                movil={movil.id}
                style={{maxWidth: "85vw"}}
                />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
  )
}

function Logo(props) {
  const [error, setError] = useState(false);
  return (
    <>
      {!error ? (
        <StyledTooltip title={props.nombre} arrow placement="top">
          <img
            className={"dt-user-avatar-image dt-user-avatar-more avatar-"+props.marca}
            src={
              process.env.PUBLIC_URL + "/images/marcas/" + props.codigo + '.png'
            }
            onError={() => setError(true)}
          />
        </StyledTooltip>
      ) : (
        <StyledTooltip title={props.nombre} arrow placement="top">
          <div className={"dt-user-avatar dt-user-avatar-more avatar-"+props.marca}>
            {props.nombre.substr(0, 2).toUpperCase()}
          </div>
        </StyledTooltip>
      )}
    </>
  );
}

function ProximosPreventivos(props) {
  const data = props.data

  function Estados (){
    let estado
    switch (true) {
      case (data.ticket != null):
        estado = <a href={'/tickets/'+data.ticket+'/edit'}>
                    <StyledTooltip title={data.servicio} arrow placement="top">
                      <img className="proximasTareas" src={PreventivoTicketRojo} />
                    </StyledTooltip>
                  </a>
        break;
      case (data.estado == 'rojo'):
        estado = <StyledTooltip title={data.servicio} arrow placement="top">
                  <img className="proximasTareas" src={PreventivoRojo} />
                </StyledTooltip>
        break;
      case (data.estado == 'amarillo'):
        estado = <StyledTooltip title={data.servicio} arrow placement="top">
                  <img className="proximasTareas" src={PreventivoAmarillo} />
                </StyledTooltip>
        break;
      case (data.estado == 'verde'):
          estado = <StyledTooltip title={data.servicio} arrow placement="top">
                    <img className="proximasTareas" src={PreventivoVerde} />
                  </StyledTooltip>
          break;
    }
    return estado;
  }

  function Calendario(){
    if(data.calendario != null){
      return(
        <StyledTooltip title={data.calendario} arrow placement="top">
          <img className="proximasTareas" src={CalendarioImg}/>
        </StyledTooltip>)
    }else return false
  }

  function Ruta(){
    if(data.ruta){
      return(
        <StyledTooltip title={data.ruta} arrow placement="top">
          <img className="proximasTareas" src={props.icono}/>
        </StyledTooltip>)
    }
    else return false
  }
  return (
    <div className='d-flex'>
      <Estados/>
      <Calendario/>
      <Ruta/>
    </div>
  );
}

function ProximosVencimientos(props) {
  let data = props.data;
  function Estados (){
    let estado
    switch (true) {
      case (data.ticket != null):
        estado = <a href={'/tickets/'+data.ticket+'/edit'}>
                    <StyledTooltip title={data.servicio} arrow placement="top">
                      <img className="proximasTareas" src={VencimientoTicketRojo} />
                    </StyledTooltip>
                  </a>
        break;
      case (data.estado == 'rojo'):
        estado = <StyledTooltip title={data.servicio} arrow placement="top">
                  <img className="proximasTareas" src={VencimientoRojo} />
                </StyledTooltip>
        break;
      case (data.estado == 'amarillo'):
        estado = <StyledTooltip title={data.servicio} arrow placement="top">
                  <img className="proximasTareas" src={VencimientoAmarillo} />
                </StyledTooltip>
        break;
      case (data.estado == 'verde'):
          estado = <StyledTooltip title={data.servicio} arrow placement="top">
                    <img className="proximasTareas" src={VencimientoVerde} />
                  </StyledTooltip>
          break;
    }
    return estado;
  }
  return (
    <>
       <div className="d-flex">
         <Estados/>
         <StyledTooltip title={data.calendario} arrow placement="top">
          <img className="proximasTareas" src={CalendarioImg}/>
        </StyledTooltip>
       </div>
    </>
  );
}

export default MovilesGridRow
